import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";

function MicrosoftCallback() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const authContext = useContext(AuthContext);
  
  useEffect(() => {
    console.log("Microsoft callback mounted");
    console.log("Current URL:", window.location.href);
    console.log("Auth context:", authContext);
    const success = searchParams.get("success");
    console.log("Microsoft callback received, success:", success);
    
    if (success === "true") {
      // Get the token from the session
      axios.get("/auth/token")
        .then(response => {
          console.log("Token response:", response.data);
          if (response.data.token) {
            // Store token in localStorage
            localStorage.setItem("token", response.data.token);
            
            // Update auth context
            authContext.setIsAuthenticated(true);
            
            // Redirect to dashboard
            navigate("/dashboards/analytics");
          } else {
            console.error("No token in response");
            setError("Authentication failed: No token received");
          }
        })
        .catch(error => {
          console.error("Error retrieving token:", error);
          setError(`Authentication failed: ${error.message}`);
        });
    } else {
      setError("Authentication failed: Invalid response from Microsoft");
    }
  }, [navigate, searchParams, authContext]);
  
  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      {error ? (
        <MDTypography variant="h5" color="error" textAlign="center">
          {error}
          <br />
          <MDTypography variant="body2" color="text">
            Redirecting to login page...
          </MDTypography>
        </MDTypography>
      ) : (
        <>
          <CircularProgress color="info" />
          <MDTypography variant="h5" mt={2}>
            Completing your Microsoft login...
          </MDTypography>
        </>
      )}
    </MDBox>
  );
}

export default MicrosoftCallback;